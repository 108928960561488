import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { ContactForm } from "../../components/contact/Form"

import { MainTitle } from "./common/text"
import { greenBox } from "./common/ornaments"
import { UnderlinedLink } from "./common/links"
import { LineWrapperWithTopSpace } from "./common/containers"
import ButtonGroup from "./atoms/ButtonGroup"
import { Button } from "./atoms/Button"
import { Signature } from "./atoms/Signature"

import Phone from "../../assets/images/svg/phone.svg"
import Message from "../../assets/images/svg/message.svg"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const ACFQuery = graphql`
  query {
    wp {
      callToAction {
        acfCallToAction {
          ctaPersonPosition
          ctaPersonName
          ctaForm
          ctaImage {
            altText
            atttachement_pl {
              altTextPl
            }
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          ctaContactOptions {
            eMail
            phone
          }
          ctaButtons {
            ctaButton1 {
              url
              title
              target
            }
            ctaButton2 {
              target
              url
              title
            }
          }
          ctaIntro
          ctaTitle
        }
        acfCallToActionPl {
          ctaPersonPositionPl
          ctaPersonNamePl
          ctaFormPl
          ctaImagePl {
            altText
            atttachement_pl {
              altTextPl
            }
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          ctaContactOptionsPl {
            eMailPl
            phonePl
          }
          ctaButtonsPl {
            ctaButton1Pl {
              url
              title
              target
            }
            ctaButton2Pl {
              target
              url
              title
            }
          }
          ctaIntroPl
          ctaTitlePl
        }
      }
    }
  }
`

const iconStyle = css`
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.7rem;
  fill: var(--orange);
`

const CallToActionContainer = styled.div`
  --footer-inner-padding: 80px;
  overflow: hidden;
  @media ${maxD.mobileL} {
    --footer-inner-padding: 40px;
  }
`

const CallToActionWrapper = styled(LineWrapperWithTopSpace)`
  @media ${maxD.mobileL} {
    flex-direction: column;
    padding: 50px 0 30px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 618px;
  flex-shrink: 0;

  padding: 0 calc(var(--footer-inner-padding) * 1.12)
    var(--footer-inner-padding) calc(var(--footer-inner-padding) * 1.12);
  z-index: 1;

  --signature-container: 2rem;

  // &::after {
  //   @media ${minD.mobileL} {
  //     content: "";
  //   }

  //   display: block;
  //   position: absolute;

  //   width: 1px;
  //   height: 100%;

  //   right: calc(var(--footer-inner-padding) * 1.12);
  //   bottom: 0;
  //   z-index: -1;

  //   background-color: var(--line-gray);
  // }


  ${greenBox}

  &::after {
    left: 7%;
    bottom: 20%;
  }

  @media ${maxD.laptopL} {
    width: 500px;
    padding: 0 calc(var(--footer-inner-padding) * 0.625)
      var(--footer-inner-padding) 0;

    &::after {
      right: calc(var(--footer-inner-padding) * 0.625);
    }
  }

  @media ${maxD.laptop} {
    width: 400px;
  }

  @media ${maxD.tabletL} {
    display: none;
  }

  @media ${maxD.mobileL} {
    display: block;
    width: 100%;

    padding: 0 0 var(--footer-inner-padding) 0;

    --signature-padding: 2rem 0 0;
  }

  &::before {
    content: "";

    display: block;
    position: absolute;

    width: 438px;
    height: 90%;
    
    top: -10%;
    left: 0px;
    z-index: -1;
    background-color: var(--brownish-gray);
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const CallToActionContent = styled.div`
  width: 100%;
  padding-bottom: var(--footer-inner-padding);
`

const CallToActionTitle = styled(MainTitle)`
  color: #fff;
`

const CallToActionIntro = styled.p`
  margin-bottom: 35px;
  line-height: 1.7;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  @media ${maxD.mobileL} {
    margin-bottom: 25px;
  }
`

const ContactData = styled.div`
  display: flex;
  margin: 45px 0 75px;
  @media ${maxD.desktop} {
    margin: 35px 0 65px;
  }
  @media ${maxD.mobileL} {
    flex-direction: column;
    margin: 20px 0 50px;
  }
`

const ContactWay = styled.figure`
  display: flex;
  align-items: center;
  color: #fff;
  &:first-of-type {
    @media ${minD.mobileL} {
      margin-right: 1.4rem;
    }
    @media ${maxD.mobileL} {
      margin-bottom: 1.5rem;
    }
  }
`

const ContactLink = styled(UnderlinedLink)`
  font-size: 1.65rem;
  &::after {
    height: 1px;
  }
  @media ${maxD.laptop} {
    font-size: 1.2rem;
  }
`

const PhoneIcon = styled(Phone)`
  ${iconStyle}
`

const MessageIcon = styled(Message)`
  ${iconStyle}
`

const CallToAction = ({ data }) => {
  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.callToAction.acfCallToAction
  const plData = acfData.wp.callToAction.acfCallToActionPl

  const isSingleCallToAction = [
    data?.ctaImage,
    data?.ctaPersonName,
    data?.ctaPersonPosition,
    data?.ctaIntro,
    data?.ctaTitle,
    data?.ctaContactOptions?.eMail,
    data?.ctaContactOptions?.phone,
    data?.form,
    data?.ctaButtons.ctaButton1,
    data?.ctaButtons.ctaButton2,
  ].some(el => el)

  let base = isSingleCallToAction ? data : enData

  let image = {
    url: getImageBySrcSetAndSize(base.ctaImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(base.ctaImage?.srcSet, 'medium') : optimizedPath(base.ctaImage?.sourceUrl),
    srcSet: getRangedSrcSet(base.ctaImage?.srcSet, 'medium'),
    alt: base.ctaImage?.altText
  }

  let signature = {
    name: base.ctaPersonName,
    position: base.ctaPersonPosition,
  }

  let intro = base.ctaIntro
  let title = base.ctaTitle

  let contactWays = {
    email: base.ctaContactOptions?.eMail,
    phone: base.ctaContactOptions?.phone,
  }

  let form = base.ctaForm

  let buttons = [
    {
      title: base.ctaButtons.ctaButton1?.title,
      url: base.ctaButtons.ctaButton1?.url,
      target: base.ctaButtons.ctaButton1?.target,
    },
    {
      title: base.ctaButtons.ctaButton2?.title,
      url: base.ctaButtons.ctaButton2?.url,
      target: base.ctaButtons.ctaButton2?.target,
    },
  ]

  if (SiteLanguage() === "pl") {
    base = plData

    image = {
      url: getImageBySrcSetAndSize(plData.ctaImagePl?.srcSet, 'medium') ? getImageBySrcSetAndSize(plData.ctaImagePl?.srcSet, 'medium') : optimizedPath(plData.ctaImagePl?.sourceUrl),
      srcSet: getRangedSrcSet(base.ctaImagePl?.srcSet, 'medium'),
      alt: plData.ctaImagePl?.atttachement_pl?.altTextPl ? plData.ctaImagePl?.atttachement_pl?.altTextPl : plData.ctaImagePl?.altText
    }

    signature = {
      name: plData.ctaPersonNamePl,
      position: plData.ctaPersonPositionPl,
    }

    intro = plData.ctaIntroPl
    title = plData.ctaTitlePl

    contactWays = {
      email: plData.ctaContactOptionsPl?.eMailPl,
      phone: plData.ctaContactOptionsPl?.phonePl,
    }

    form = plData.ctaFormPl

    buttons = [
      {
        title: plData.ctaButtonsPl.ctaButton1Pl?.title,
        url: plData.ctaButtonsPl.ctaButton1Pl?.url,
        target: plData.ctaButtonsPl.ctaButton1Pl?.target,
      },
      {
        title: plData.ctaButtonsPl.ctaButton2Pl?.title,
        url: plData.ctaButtonsPl.ctaButton2Pl?.url,
        target: plData.ctaButtonsPl.ctaButton2Pl?.target,
      },
    ]
  }

  return (
    <CallToActionContainer>
      <CallToActionWrapper
        flex
        spaceBetween
        simple
        style={{
          "--color": "var(--line-gray)",
          "--does-bold-line-exists": "none",
        }}
      >
        <ImageContainer>
          {image.url && (
            <Image
              loading="lazy"
              src={image.url}
              srcSet={image.srcSet}
              alt={image.alt}
              style={{ overflow: "visible" }}
            />
          )}
          {(signature.name || signature.position) && (
            <Signature
              name={signature.name}
              position={signature.position}
              style={{ color: "#fff", padding: "var(--signature-padding)" }}
            />
          )}
        </ImageContainer>
        <CallToActionContent>
          {intro && <CallToActionIntro>{intro}</CallToActionIntro>}
          {title && (
            <CallToActionTitle
              dangerouslySetInnerHTML={{ __html: SiteLanguage() === "pl" ? plData.ctaTitlePl : enData.ctaTitle }}
              style={{
                "--theme-color": "var(--orange)",
              }}
            ></CallToActionTitle>
          )}
          {(contactWays.phone || contactWays.email) && (
            <ContactData>
              {contactWays.phone && (
                <ContactWay>
                  <PhoneIcon />
                  <ContactLink
                    as="a"
                    href={`tel:${contactWays.phone.replace(" ", "")}`}
                    style={{ "--link-color": "#fff" }}
                  >
                    {contactWays.phone}
                  </ContactLink>
                </ContactWay>
              )}
              {contactWays.email && (
                <ContactWay>
                  <MessageIcon />
                  <ContactLink
                    as="a"
                    href={`mailto:${contactWays.email}`}
                    style={{ "--link-color": "#fff" }}
                  >
                    {contactWays.email}
                  </ContactLink>
                </ContactWay>
              )}
            </ContactData>
          )}
          {form && (
            <ContactForm
              containerStyle={{ "--color": "#fff", color: "#fff" }}
            />
          )}
          {(buttons[0].url || buttons[1].url) && (
            <ButtonGroup>
              {buttons[0].url && (
                <Button
                  theme="green"
                  to={buttons[0].url}
                  target={buttons[0].target}
                >
                  {buttons[0].title}
                </Button>
              )}
              {buttons[1].url && (
                <Button
                  theme="green"
                  to={buttons[1].url}
                  target={buttons[1].target}
                >
                  {buttons[1].title}
                </Button>
              )}
            </ButtonGroup>
          )}
        </CallToActionContent>
      </CallToActionWrapper>
    </CallToActionContainer>
  )
}

export default CallToAction